// @ts-nocheck
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import ShareIcon from "@material-ui/icons/Share";
import React, { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import addCenterIcon from "src/assets/icons/sidebar_nav/addCenterIcon.png";
import { toggleLanguagePopup, toggleSideBar } from "../../../actions";
import blogIcon from "../../../assets/icons/sidebar_nav/blog.svg";
import changeLanguageIcon from "../../../assets/icons/sidebar_nav/change_language-white.svg";
import changePasswordIcon from "../../../assets/icons/sidebar_nav/change_password.svg";
import logoutIcon from "../../../assets/icons/sidebar_nav/logout.svg";
import logoutAllIcon from "../../../assets/icons/sidebar_nav/logout_all.svg";
import profileIcon from "../../../assets/icons/sidebar_nav/profile.svg";
import subscriptionIcon from "../../../assets/icons/sidebar_nav/subscription.svg";
import { useLanguage } from "../../language/LanguageController";
import analytics from "../../library/firebase/analytics";
import { socialSharing } from "../../library/util";

import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from "@material-ui/core";
import RedeemIcon from "@material-ui/icons/Redeem";
import { useState } from "react";
import { Link } from "react-router-dom";
import { selectDairy } from "src/actions/dairy";
import { resetCustomers } from "src/actions/dairyCustomer";
import { resetDairyCustomerProducts } from "src/actions/dairyCustomerProduct";
import { resetRatelists } from "src/actions/dairyRateList";
import { resetDairyUsers } from "src/actions/dairyUsers";
import { resetDairyProducts } from "src/actions/product";
import { resetSubscription } from "src/actions/subscriptions";
import { logoutAll, logoutUser } from "src/actions/user";
import ChangeCenter from "src/assets/icons/change_dairyCenter_icon.png";
import ModalDialog from "src/components/common/ModalDialog";
import { getDairyCount, getDairyName } from "src/reducers/dairy";
import DairyCenterSelectionModal from "src/components/modalPopups/dairyCenterSelectionModal.js";
import { useMobile } from "src/components/library/detect-device";
import User from "src/components/models/User";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundImage: "linear-gradient(61deg,#11b232 8%,#6e78f7 91%)",
  },
  menuButton: {
    display: "inline-flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  homeButton: {
    marginRight: theme.spacing(2),
    width: "84px",
    "&:hover": {
      color: "#fff",
    },
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
    },
  },
  backButton: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userPopover: {
    marginTop: "8px",
    marginLeft: "7px",
    overflow: "visible",
    minWidth: "250px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: "center",
  },
  avtarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: "1px solid #525252",
  },
}));

const InnerHeaderSmall = ({ title, redirect = false }) => {
  const classes = useStyles();
  const { t } = useLanguage();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const userPopoverId = "user-popover-menu";
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);
  const { user } = useSelector((state) => state.userReducer);
  const dairyCount = useSelector(getDairyCount);
  const customerGroupId = user.customer_group_id;
  const dairyName = useSelector(getDairyName);
  const [openModal, setOpenModal] = useState(false);
  const { dairies, dairy } = useSelector((state) => state.dairy);
  const [selectedDairyId, setSelectedDairyId] = useState(dairy?.id);
  const { pathname } = location;
  const isMobile = useMobile();
  const onDairySelected = useCallback(
    (dairy) => {
      dispatch(selectDairy(dairy));
      dispatch(resetSubscription());
      dispatch(resetRatelists());
      dispatch(resetCustomers());
      dispatch(resetDairyProducts());
      dispatch(resetDairyUsers());
      dispatch(resetDairyCustomerProducts());

      if (pathname.startsWith("/milk/customer")) {
        history.replace("/milk/customer");
      }
      if (pathname.startsWith("/customers/edit")) {
        isMobile
          ? history.replace("/customers")
          : history.replace("/milk/customer");
      }
      if (pathname.startsWith("/customers/milk/summary")) {
        history.replace("/milk/customer");
      }
      if (pathname.startsWith("/dairy/product/edit")) {
        history.replace("/dairy/product");
      }
      if (pathname.startsWith("/dairy/product/detail")) {
        history.replace("/dairy/product");
      } else if (redirect) {
        history.replace("/dairy");
      }
    },
    [dispatch, history, redirect, location]
  );

  const excludedPaths = [
    "/rewards",
    "/reward-history",
    "/help",
    "/contact",
    "/subscription/plans",
    "/subscription/billingCycle",
    "/subscription/checkout",
    "/dairy/add",
    "/forgotPassword",
    "/account/profile",
    "/account/changePassword",
    "/account/setPassword",
    "/account/address/edit",
    "/gestation",
    "/gestation/add",
  ];

  const showDairyChangeButton =
    !excludedPaths.includes(location.pathname) && customerGroupId != 1;

  const showAddCenter = customerGroupId != 1;

  const shareApp = () => {
    analytics.logEvent("shareApp");
    const message = `${t("liter_title")}\n\n${t("text_download_now")}:\nhttps://play.google.com/store/apps/details?id=in.liter.live`;
    socialSharing(message, "", "share_app");
  };

  const handleUserPopoverClick = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserPopoverClose = () => {
    setUserAnchorEl(null);
  };

  const [rightMenu, setRightMenu] = React.useState(null);

  const openRightMenu = (event) => {
    setRightMenu(event.currentTarget);
  };

  const closeRightMenu = () => {
    setRightMenu(null);
  };

  const toggleModal = () => setOpenModal(!openModal);

  return (
    <div className="row">
      <AppBar className={classes.appBar} elevation={2}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open Menu"
            onClick={() => dispatch(toggleSideBar())}
          >
            <Avatar
              alt={user.firstname + " " + user.lastname}
              src={user.profile_pic}
              className={classes.avtarSmall}
              variant="rounded"
            >
              <AccountCircle />
            </Avatar>
          </IconButton>
          {location.pathname != "/" && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Back"
              className={classes.backButton}
              onClick={history.goBack}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          )}
          <Button
            edge="start"
            className={classes.homeButton}
            color="inherit"
            aria-label="Home"
            component={Link}
            to="/"
          >
            <HomeIcon fontSize="large" />
          </Button>
          <Typography variant="h6" component="h1" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          {dairyCount > 1 && showDairyChangeButton && (
            <Button
              edge="end"
              aria-label={t("text_dairy_center")}
              color="inherit"
              className="mr-2"
              onClick={toggleModal}
            >
              <img src={ChangeCenter} className="max-width-30" alt="" />
            </Button>
          )}
          <DairyCenterSelectionModal
            isOpen={openModal}
            onClose={toggleModal}
            onClick={toggleModal}
            dairies={dairies}
            selectedDairyId={selectedDairyId}
            onDairySelected={onDairySelected}
            title={t("text_dairy_center")}
          />

          <div className={classes.sectionDesktop}>
            <Button
              edge="end"
              aria-label={t("text_contact_us")}
              aria-haspopup="true"
              color="inherit"
              className="mr-2"
              component={Link}
              to="/contact"
            >
              <HeadsetMic />
            </Button>
            <Button
              edge="end"
              aria-label={t("text_choose_language")}
              aria-haspopup="true"
              color="inherit"
              className="mr-2"
              onClick={() => dispatch(toggleLanguagePopup(true))}
            >
              <img src={changeLanguageIcon} alt="" className="max-width-30" />
            </Button>
            <IconButton
              edge="end"
              aria-describedby={userPopoverId}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleUserPopoverClick}
              color="inherit"
            >
              <Avatar src={user.profile_pic}>
                <AccountCircle fontSize="large" />
              </Avatar>
            </IconButton>
            <Popover
              id={userPopoverId}
              open={Boolean(userAnchorEl)}
              anchorEl={userAnchorEl}
              onClose={handleUserPopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: `user-menu-popover ${classes.userPopover}`,
              }}
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={user.profile_pic}>
                      <AccountCircle />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.firstname} ${user.lastname}`}
                    secondary={
                      <>
                        {parseInt(user.customer_group_id) === 2
                          ? t("text_dairy_owner")
                          : t("text_kisan")}
                      </>
                    }
                  />
                </ListItem>
              </List>
              <Divider />
              <List component="nav" aria-label="main mailbox folders">
                {showAddCenter && (
                  <ListItem button component={Link} to="/dairy/add">
                    <ListItemIcon>
                      <img
                        src={addCenterIcon}
                        className="max-width-20"
                        alt=""
                      />
                    </ListItemIcon>
                    <ListItemText primary={t("text_add_center")} />
                  </ListItem>
                )}
                <ListItem button component={Link} to="/account/profile">
                  <ListItemIcon>
                    <img src={profileIcon} className="max-width-25" alt="" />
                  </ListItemIcon>
                  <ListItemText primary={t("text_profile")} />
                </ListItem>
                {parseInt(user.customer_group_id) === 2 && (
                  <ListItem button component={Link} to="/subscription">
                    <ListItemIcon>
                      <img
                        src={subscriptionIcon}
                        className="max-width-20"
                        alt=""
                      />
                    </ListItemIcon>

                    <ListItemText primary={t("text_subscription")} />
                  </ListItem>
                )}

                <ListItem button component={Link} to="/rewards">
                  <ListItemIcon>
                    <RedeemIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("text_reward")} />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="https://blog.liter.live"
                  target="_blank"
                >
                  <ListItemIcon>
                    <img src={blogIcon} className="max-width-20" alt="" />
                  </ListItemIcon>
                  <ListItemText primary={t("text_blog")} />
                </ListItem>
                <ListItem button component={Link} to="/account/changePassword">
                  <ListItemIcon>
                    <img
                      src={changePasswordIcon}
                      className="max-width-20"
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("text_change_password")} />
                </ListItem>
                <ListItem button component={Link} to="/help">
                  <ListItemIcon>
                    <HelpOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("text_help")} />
                </ListItem>
                <ListItem button onClick={logoutUser}>
                  <ListItemIcon>
                    <img src={logoutIcon} className="max-width-20" alt="" />
                  </ListItemIcon>
                  <ListItemText primary={t("text_logout")} />
                </ListItem>
                <ListItem button onClick={logoutAll}>
                  <ListItemIcon>
                    <img src={logoutAllIcon} className="max-width-20" alt="" />
                  </ListItemIcon>
                  <ListItemText primary={t("text_logout_from_all_device")} />
                </ListItem>
              </List>
            </Popover>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              color="inherit"
              onClick={openRightMenu}
              className="mr-n3"
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="header-right-menu"
              anchorEl={rightMenu}
              keepMounted
              open={Boolean(rightMenu)}
              onClose={closeRightMenu}
            >
              <MenuItem onClick={shareApp}>
                <ShareIcon fontSize="small" />
                &nbsp;&nbsp;{t("text_share")}
              </MenuItem>
              <MenuItem component={Link} to="/contact">
                <HeadsetMic fontSize="small" />
                &nbsp;&nbsp;{t("text_contact_us")}
              </MenuItem>
              <MenuItem component={Link} to="/help">
                <HelpIcon fontSize="small" />
                &nbsp;&nbsp;{t("text_help")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

InnerHeaderSmall.defaultProps = {
  toolbar: true,
  right: true,
};

export default InnerHeaderSmall;
