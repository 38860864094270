// @ts-nocheck
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import withLanguage from "../../language/LanguageController";

import customerImg from "../../../assets/icons/customer.svg";
import loanDashboardImg from "../../../assets/icons/loan-dashboard.png";
import tableDataImg from "../../../assets/icons/table-data.png";
import reportImg from "../../../assets/icons/report.png";
import addCustomerIcon from "../../../assets/icons/add_customer.png";
import productSellIcon from "../../../assets/icons/cart.png";
import advancePaymentIcon from "../../../assets/icons/down-payment.png";
import productManagementIcon from "../../../assets/icons/inventory-management.png";
import paymentDetailsIcon from "../../../assets/icons/payment_details.png";
import customerBillIcon from "../../../assets/icons/customer_bill.png";
import totalBuySellIcon from "../../../assets/icons/accounts.png";
import buySellSummaryIcon from "../../../assets/icons/buy_sell_summary.png";
import customerMilkIcon from "../../../assets/icons/customer_milk.png";
import addMilkIcon from "../../../assets/icons/milk-tank.png";

import { Drawer, Grid, Link, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { checkFeatureInSubscription } from "src/components/common/FeatureFlag/FeatureFlag";
import { useBrowser, useMobile } from "src/components/library/detect-device";
import analytics from "src/components/library/firebase/analytics";
import {
  ABILITY_ADVANCE_PAYMENT,
  ABILITY_DAIRY_BILL,
  ABILITY_DAIRY_CUSTOMERS,
  ABILITY_DAIRY_RATE_LIST,
  ABILITY_DAIRY_REPORT,
  ABILITY_DAIRY_SETTING,
  ABILITY_DAIRY_USERS,
  ABILITY_LOAN,
  ABILITY_MILK_COLLECTION,
  ABILITY_PRODUCT_MANAGEMENT,
  ABILITY_PRODUCT_SALE,
  PERMISSION_ADD,
  PERMISSION_CUSTOMER_REPORT,
  PERMISSION_DAIRY_REPORT,
  PERMISSION_DETAIL,
  PERMISSION_SALE_ADD,
  PERMISSION_VIEW,
  PERMISSION_VIEW_CUSTOMER_BILL,
  PERMISSION_VIEW_DAIRY_BILL,
} from "../../../_constants/permissions";
import { HasPermission, usePermission } from "../../common/AccessPermission";
import { SecondaryFab } from "../../core/Fab";

const DairyMainNavigation = ({ t }) => {
  const history = useHistory();
  const isMobile = useMobile();
  const isBrowser = useBrowser();
  const { hasAccess } = usePermission();
  const [isCustomerMenuOpen, setIsCustomerMenuOpen] = useState(false);
  const [isMilkMenuOpen, setIsMilkMenuOpen] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isBillMenuOpen, setIsBillMenuOpen] = useState(false);
  const [isLoanMenuOpen, setIsLoanMenuOpen] = useState(false);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false);
  const [moreMenuDrawer, setMoreMenuDrawer] = useState(false);
  const [active, setActive] = useState("");

  const showMoreMenu = () => {
    setMoreMenuDrawer(true);
  };

  const showSubMenu = (type, feature = null) => {
    analytics.logEvent("showSubMenu", {
      subMenu: type,
    });
    if (!feature || checkFeatureInSubscription(feature)) {
      setIsCustomerMenuOpen(type === "customer" ? true : false);
      setIsMilkMenuOpen(type === "milk" ? true : false);
      setIsProductMenuOpen(type === "product" ? true : false);
      setIsBillMenuOpen(type === "bill" ? true : false);
      setIsLoanMenuOpen(type === "loan" ? true : false);
      setIsSettingMenuOpen(type === "setting" ? true : false);
    }
  };

  const changePage = (pathToGo, feature = null) => {
    if (!feature || checkFeatureInSubscription(feature)) {
      history.push(pathToGo);
    }
  };

  const getMenuIcon = (Icon, isMui) => {
    return isMui ? <Icon /> : <img src={Icon} className="max-w-25" alt="" />;
  };

  const hasCustomersAccess =
    hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_ADD) ||
    hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_VIEW);
  const hasMilkPermission =
    hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD) ||
    hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL) ||
    hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL) ||
    hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL);
  const hasProductAccess =
    hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) ||
    hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) ||
    hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW);
  const hasBillPermission =
    hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL) ||
    hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL) ||
    hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW) ||
    hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD) ||
    hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL);
  const hasLoanAccess =
    hasAccess(ABILITY_LOAN, PERMISSION_VIEW) ||
    hasAccess(ABILITY_LOAN, PERMISSION_ADD);
  const hasReportAccess =
    hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_DAIRY_REPORT) ||
    hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_CUSTOMER_REPORT);
  const hasSettingAccess =
    hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_VIEW) ||
    hasAccess(ABILITY_DAIRY_SETTING, PERMISSION_VIEW) ||
    hasAccess(ABILITY_DAIRY_USERS, PERMISSION_VIEW);
  const hasAutoCollectionAccess =
    window?.literAPI?.desktop &&
    hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD) &&
    (checkFeatureInSubscription("offline_collection", false) ||
      checkFeatureInSubscription("automatic_collection", false));

  if (isBrowser) {
    return null;
  }

  const milkSubMenus = [
    {
      text: t("text_milk_buy_sell"),
      testid: "nav_milk_collection",
      onClick: () => changePage("/milk/collection"),
      hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD),
    },
    {
      testid: "nav_milk_collection_auto",
      text: t("text_auto_collection"),
      onClick: () => changePage("/milk/collection/auto"),
      hasAccess: hasAutoCollectionAccess,
    },
    {
      text: t("text_total_buy_sell"),
      testid: "nav_total_collection",
      onClick: () => changePage("/milk/totalCollection", "daily_milk_total"),
      hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL),
    },
    {
      text: t("text_buy_sell_summary"),
      testid: "nav_collection_summary",
      onClick: () => changePage("/milk/summary", "dairy_milk_summary"),
      isMobile: true,
      hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL),
    },
    {
      text: t("text_customer_milk"),
      testid: "nav_customer_milk",
      onClick: () => changePage("/milk/customer"),
      hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL),
    },
  ];

  return (
    <>
      <BottomNavigation
        value={active}
        id="dairy-main-navigation"
        className="border-top fixed-bottom w-100 justify-content-start justify-content-sm-center dairy-main-navigation align-items-center h-auto shadow-top"
      >
        {hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_ADD) && (
          <BottomNavigationAction
            showLabel={true}
            feature={"customer_limit"}
            checkLimit
            className="text-dark font-weight-bold"
            label={
              <span className="d-inline-block mt-1">
                {t("text_add_customer")}
              </span>
            }
            value={"Customer"}
            onClick={(e) => changePage("/customers/add")}
            icon={getMenuIcon(addCustomerIcon)}
          />
        )}
        {hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD) &&
          hasMilkPermission && (
            <BottomNavigationAction
              showLabel={true}
              className="text-dark mt-4 font-weight-bold"
              label={
                <span className="d-inline-block mt-1">
                  {t("text_add_milk")}
                </span>
              }
              value={"Milk"}
              onClick={(e) => changePage("/milk/collection")}
              icon={
                <span className="addMilkIcon">{getMenuIcon(addMilkIcon)}</span>
              }
            />
          )}
        <BottomNavigationAction
          showLabel={true}
          className="text-dark font-weight-bold"
          label={<span className="d-inline-block mb-1">{t("text_more")}</span>}
          value={"More"}
          onClick={showMoreMenu}
          icon={<MenuIcon className="font-size-1_8r" />}
        />
      </BottomNavigation>

      <Drawer
        anchor={"bottom"}
        transitionDuration={300}
        open={moreMenuDrawer}
        onClose={() => setMoreMenuDrawer(false)}
        className="dairy-main-navigation-drawer"
        elevation={0}
        PaperProps={{
          className: "overflow-visible-i",
        }}
      >
        <div className="d-flex justify-content-center">
          <SecondaryFab
            aria-label="add"
            className="mx-1 position-absolute top-n20"
            size="medium"
            variant="extended"
            onClick={() => setMoreMenuDrawer(false)}
          >
            <KeyboardArrowDownIcon fontSize="large" className="text-white" />
          </SecondaryFab>
        </div>
        <div
          role="presentation"
          onClick={(e) => setMoreMenuDrawer(false)}
          onKeyDown={(e) => setMoreMenuDrawer(false)}
          className="py-2 px-2"
        >
          {(hasProductAccess || hasLoanAccess) && (
            <div className="mb-3 mt-4">
              <Typography
                variant="body1"
                className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
              >
                {t("text_product_loan_and_ratelist")}
              </Typography>
              <Grid container className="pt-1" spacing={3}>
                <HasPermission
                  ability={ABILITY_PRODUCT_SALE}
                  access={PERMISSION_SALE_ADD}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_add_sales"
                      to="dairy/product/addSales"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_subscription"
                    >
                      <img
                        src={productSellIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_product_sell")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_PRODUCT_MANAGEMENT}
                  access={PERMISSION_VIEW}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_manage_products"
                      to="dairy/product"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_gestation"
                    >
                      <img
                        src={productManagementIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_manage_products")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission ability={ABILITY_LOAN} access={PERMISSION_ADD}>
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_sanction_loan"
                      to="/dairy/loan"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark"
                    >
                      <img
                        src={loanDashboardImg}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">{t("text_loan")}</Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_DAIRY_RATE_LIST}
                  access={PERMISSION_VIEW}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_rate_list"
                      to="/dairy/setting/rateList"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_rate_list"
                    >
                      <img
                        src={tableDataImg}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_rate_list")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
              </Grid>
            </div>
          )}

          {(hasBillPermission || hasReportAccess) && (
            <div className="mb-3">
              <Typography
                variant="body1"
                className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
              >
                {t("text_reports_and_bill")}
              </Typography>
              <Grid container className="pt-1" spacing={3}>
                {hasReportAccess && (
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_dairy_report"
                      onClick={() => changePage("/dairy/report", "reports")}
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_dairy_report"
                    >
                      <img
                        src={reportImg}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_reports")}
                      </Typography>
                    </Link>
                  </Grid>
                )}
                <HasPermission
                  ability={ABILITY_DAIRY_BILL}
                  access={PERMISSION_VIEW_DAIRY_BILL}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_dairy_bill"
                      to="dairy/bill"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_subscription"
                    >
                      <img
                        src={paymentDetailsIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_dairy_bill")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_DAIRY_BILL}
                  access={PERMISSION_VIEW_CUSTOMER_BILL}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_customers_bill"
                      to="dairy/bill/customer"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_rewards"
                    >
                      <img
                        src={customerBillIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_customers_bill")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_ADVANCE_PAYMENT}
                  access={PERMISSION_VIEW || PERMISSION_ADD}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_pay_in_advance"
                      to="dairy/advancePayment"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark nav_gestation"
                    >
                      <img
                        src={advancePaymentIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_pay_in_advance")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
              </Grid>
            </div>
          )}

          {(hasMilkPermission || hasCustomersAccess) && (
            <div className="mb-3">
              <Typography
                variant="body1"
                className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
              >
                {t("text_customers_and_milk_details")}
              </Typography>
              <Grid container className="pt-1" spacing={3}>
                <HasPermission
                  ability={ABILITY_DAIRY_CUSTOMERS}
                  access={PERMISSION_VIEW}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_customer_list"
                      to="/customers"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark"
                    >
                      <img
                        src={customerImg}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_customers")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_MILK_COLLECTION}
                  access={PERMISSION_DETAIL}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_total_collection"
                      to="/milk/totalCollection"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark"
                    >
                      <img
                        src={totalBuySellIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_total_buy_sell")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_MILK_COLLECTION}
                  access={PERMISSION_DETAIL}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_collection_summary"
                      to="/milk/summary"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark"
                    >
                      <img
                        src={buySellSummaryIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_buy_sell_summary")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
                <HasPermission
                  ability={ABILITY_MILK_COLLECTION}
                  access={PERMISSION_DETAIL}
                >
                  <Grid item xs={3}>
                    <Link
                      data-auto="nav_customer_milk"
                      to="/milk/customer"
                      component={RouterLink}
                      className="d-flex flex-column align-items-center text-center text-dark"
                    >
                      <img
                        src={customerMilkIcon}
                        className="mb-2 min-w-30 max-w-30"
                        alt=""
                      />
                      <Typography variant="body2">
                        {t("text_customer_milk")}
                      </Typography>
                    </Link>
                  </Grid>
                </HasPermission>
              </Grid>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

DairyMainNavigation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withLanguage(DairyMainNavigation);
